import React from "react";
import styles from '@views/CaraOrder/order.module.scss'

export interface ButtonProps {
    nextSlide: React.MouseEventHandler
}

export default function Button({ nextSlide }: ButtonProps) {
  return (
    <div onClick={nextSlide} className={styles.buttonSlides}>
      <img src={require('@assets/images/icon/icon-arrow-down.svg')} />
      <span>Lihat semua step cara order</span>
    </div>
  );
}
