import React from "react";
import styles from "@views/CaraOrder/order.module.scss";
import { order } from "@utils/static-data";

type OrderTypes = typeof order[0];

type DetailProps = {
  active: OrderTypes;
};

export default function Detail({ active }: DetailProps) {
  const { title, details } = active;
  return (
    <div className={styles.detail}>
      <h5>
        {title.map((text, index) => (
          <span key={index}>{text}&nbsp;</span>
        ))}
      </h5>

      {details.map((detail, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: detail }}></p>
      ))}
    </div>
  );
}
