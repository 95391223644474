import React, { createRef } from "react";
import Slider, { Settings } from "react-slick";
import FirstSlides from "./Slider/FirstSlides";
import LastSlides from "./Slider/LastSlides";

import styles from './order.module.scss'


export default function FCSlider() {
  const ref = createRef<Slider>();

  /**
   * Trigger event next slide
   * used in button
   */
  const nextSlide: React.MouseEventHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (ref.current) {
      ref.current.slickNext();
    }
  };

  /**
   * Trugger event previous slide
   * used in button
   */
  const prevSlide: React.MouseEventHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (ref.current) {
      ref.current.slickPrev();
    }
  };

  /**
   * Slider Configuratiion
   */
  const slideConfig: Settings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: styles.sliders
  };
  return (
    <Slider ref={ref} {...slideConfig}>
      <FirstSlides nextSlide={nextSlide} />
      <LastSlides prevSlide={prevSlide} />
    </Slider>
  );
}
