import React from "react";
import styles from "@views/CaraOrder/order.module.scss";

export default function Titles() {
  return (
    <div className={styles.titles}>
      <h1>
        <p>4 Cara Mudah</p>
        <p>Pesan Seragam</p>
      </h1>
      <div className={styles.subTitle}>
        <p>Selalu memudahkan dan</p>
        <p>menguntungkan anda</p>
      </div>
    </div>
  );
}
