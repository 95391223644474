import React from "react";
import styles from "@views/CaraOrder/order.module.scss";

export default function Titles() {
  return (
    <div className={styles.title}>
      <h1>
        Penjelasan <span>Cara Order</span>
      </h1>
    </div>
  );
}
