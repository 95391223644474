import React from "react";
import styles from "@views/CaraOrder/order.module.scss";

export interface ButtonProps {
  prevSlide: React.MouseEventHandler;
}

export default function Button({ prevSlide }: ButtonProps) {
  return (
    <div className={styles.buttonSlides} onClick={prevSlide}>
      <img src={require("@assets/images/icon/icon-arrow-up.svg")} />
      <span className={styles.back}>Kembali untuk melihat video cara order</span>
    </div>
  );
}
