import React from "react";
import Video from "@components/Video";
import styles from "@views/CaraOrder/order.module.scss";

export default function FCVideo() {
  return (
    <div className={styles.video}>
      <Video
        link={"https://www.youtube.com/embed/tVRvFQIMcaA"}
        quality={"sd"}
        imgStyle={{
          borderRadius: 0,
        }}
      />
    </div>
  );
}
