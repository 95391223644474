import React from "react";
import Titles from './LastSlides/Titles'
import Penjelasan from './LastSlides/Penjelasan'
import Button, { ButtonProps } from './LastSlides/Button'
import styles from '@views/CaraOrder/order.module.scss'

interface LastSlidesProps extends ButtonProps {}

export default function LastSlides(props: LastSlidesProps) {
  return (
    <section className={styles.section}>
      <div className={styles.lastSlides}>
          <Titles />
          <Penjelasan />
          <Button prevSlide={props.prevSlide} />
      </div>
    </section>
  );
}
