import React from "react";
import Video from "./FirstSlides/Video";
import Titles from "./FirstSlides/Titles";
import Button, { ButtonProps } from "./FirstSlides/Button";
import styles from "@views/CaraOrder/order.module.scss";

interface FirstSlidesProps extends ButtonProps {}

export default function FirstSlides(props: FirstSlidesProps) {
  return (
    <section className={styles.section}>
      <div className={styles.firstSlides}>
        <div className={styles.contents}>
          <Video />
          <Titles />
        </div>
        <Button nextSlide={props.nextSlide} />
      </div>
    </section>
  );
}
