import React from "react";
import Img from "gatsby-image";
import { FluidObject } from "gatsby-image";

import styles from './order.module.scss'

type HeroBackgroundProps = { background: FluidObject[] };

export default function HeroBackground({ background }: HeroBackgroundProps) {
  return (
    <div className={styles.heroBackground}>
      <Img
        fluid={background}
        alt="layanan-background"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
