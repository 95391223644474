import React, { useState } from "react";
import Detail from "./Penjelasan/Detail";
import List from "./Penjelasan/List";

import styles from "@views/CaraOrder/order.module.scss";
import { order } from "@utils/static-data";

export default function Penjelasan() {
  const [active, setActive] = useState(order[0]);
  return (
    <div className={styles.penjelasan}>
      <List active={active} setActive={setActive} />
      <Detail active={active} />
    </div>
  );
}
