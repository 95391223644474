import React from "react";
import Layout from "@components/Layout";
import Slider from "@views/CaraOrder/Slider";
import HeroBackground from "@views/CaraOrder/HeroBackground";
import { CaraOrderPage } from "src/types";

export default function CaraOrder(props: CaraOrderPage) {
  const { desktopImg, mobileImg } = props.data;

  /**
   * hero images Array object
   * @types FluidObject[]
   */
  const heroImages = [
    mobileImg.childImageSharp.fluid,
    {
      ...desktopImg.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <Layout noFooter>
      <HeroBackground background={heroImages} />
      <Slider />
    </Layout>
  );
}
