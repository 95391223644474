import React from "react";
import styles from "@views/CaraOrder/order.module.scss";
import { order } from "@utils/static-data";

type OrderTypes = typeof order[0];

type DetailProps = {
  active: OrderTypes;
  setActive: React.Dispatch<React.SetStateAction<OrderTypes>>;
};

export default function List({ active, setActive }: DetailProps) {
  return (
    <div className={styles.list}>
      <h6>Pilih step sesuai urutan</h6>
      <div className={styles.pointWrapper}>
        {order.map((object, index) => (
          <div
            key={index}
            className={active === object ? "active" : ""}
            onClick={() => setActive(object)}
          >
            <span>{index + 1}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
